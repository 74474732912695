/** @jsxImportSource @emotion/react */
import { css, CSSObject, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { FormikHelpers } from 'formik';
import React, { CSSProperties } from 'react';
import Select, { components, OptionProps, Props as SelectProps, StylesConfig, ValueContainerProps } from 'react-select';

import { TValue } from '../../@types/models';
import { COLORS, fontAvenirRoman } from '../../constants/styles';
import { Flex } from '../Div';
import { Paragraph } from '../Paragraph';
import { checkboxStyle } from './Checkbox';
import { Label } from './Label';

const baseStyle = css`
  ${fontAvenirRoman};
  appearance: none;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  line-height: 20px;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  height: 40px;
  color: ${COLORS.slate};
  cursor: pointer;
`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const selectStyles = (hasErrors = false, hasWarnings = false, _touched = false): any => ({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  container: (base: CSSProperties, state: any) => {
    const computedBorderColor = () => {
      if (hasErrors) {
        return COLORS.lightRed;
      }

      if (hasWarnings) {
        return COLORS.lightOrange;
      }

      if (state.isFocused) {
        return COLORS.iris;
      }

      return COLORS.lightPeriwinkle;
    };

    return {
      ...base,
      border: `1px solid ${computedBorderColor()} !important`,
      borderBottomColor: `${computedBorderColor()} !important`,
      borderBottomLeftRadius: `${state.isFocused ? 0 : '5px'} !important`,
      borderBottomRightRadius: `${state.isFocused ? 0 : '5px'} !important`,
      backgroundColor: state.isDisabled ? COLORS.paleGrey : COLORS.white,
    };
  },
  control: (base: CSSProperties) => ({
    ...base,
    border: 'none',
    height: '100%',
    cursor: 'pointer',
    backgroundColor: 'none',
    boxShadow: 'none',
    borderRadius: 'none',
  }),
  indicatorSeparator: (base: CSSProperties) => ({
    ...base,
    display: 'none',
  }),
  menu: (base: CSSProperties) => ({
    ...base,
    marginTop: 0,
    position: 'relative',
    top: '-1px',
    zIndex: 1,
    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '0 0 5px 5px',
    border: `1px solid ${hasErrors ? COLORS.lightRed : COLORS.iris}`,
    borderTopColor: 'transparent',
    width: 'calc(100% + 2px)',
    left: '-1px',
  }),
  menuList: (base: CSSProperties) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    ['&:last-child']: {
      borderRadius: '0 0 5px 5px',
    },
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: (base: CSSProperties, state: any) => ({
    ...base,
    backgroundColor: state.isSelected || state.isFocused ? COLORS.paleGrey : COLORS.white,
    cursor: 'pointer',
    borderTop: `1px solid ${COLORS.lightPeriwinkle}`,
    color: COLORS.slate,
    padding: '8px 18px',
    position: 'relative',
    zIndex: 10,
    ['&:active, &:focus, &:hover']: {
      backgroundColor: COLORS.paleGrey,
    },
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  placeholder: (base: CSSProperties, state: any) => ({
    ...base,
    color: state.isDisabled ? COLORS.cloudyBlue : COLORS.blueGrey,
    opacity: 1,
    margin: 0,
  }),
  valueContainer: (base: CSSProperties) => ({
    ...base,
    padding: '2px 15px',
    alignItems: 'center',
  }),
  singleValue: (base: CSSProperties) => ({
    ...base,
    color: COLORS.slate,
  }),
});

const MultiCount = styled(Flex)`
  ${fontAvenirRoman};
  height: 25px;
  border-radius: 12.5px;
  min-width: 25px;
  width: auto;
  background-color: ${COLORS.iris};
  font-size: 12px;
  line-height: 20px;
  letter-spacing: normal;
  align-items: center;
  justify-content: center;
  color: ${COLORS.white};
  padding: 0 7px;
`;

interface Props extends SelectProps {
  errors?: boolean;
  handleTouch?: (v?: string) => void;
  handleChange?: (v: TValue | TValue[] | unknown) => void;
  /** Optional @label property will show a label element with text */
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: any;
  multiLabel?: string;
  name: string;
  options: TValue[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue?: FormikHelpers<any>['setFieldValue'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldTouched?: FormikHelpers<any>['setFieldTouched'];
  /** Optional @subtext to be displayed under the input element  */
  subtext?: string;
  /** Optional @warnings to indicate if there are warnings associated with this select  */
  warnings?: boolean;
  wrapperStyle?: CSSObject | SerializedStyles;
}

interface InputProps extends Props {
  customStyles?: StylesConfig;
  touched?: boolean;
}

class SelectInput extends React.Component<InputProps> {
  public render() {
    const { className, customStyles, errors, isMulti, multiLabel, warnings, touched, ...props } = this.props;

    const cbStyle = css`
      ${checkboxStyle()};
      margin-right: 10px;

      &:not(:disabled) {
        &:focus,
        &:checked,
        &.checked,
        &:hover {
          border-color: ${COLORS.lightPeriwinkle};
        }
      }
    `;

    const Option = (optionProps: OptionProps) => {
      const { children, isSelected } = optionProps;
      return (
        <components.Option {...optionProps}>
          <Flex>
            <Flex css={cbStyle} className={isSelected ? 'checked' : undefined} />
            {children}
          </Flex>
        </components.Option>
      );
    };

    const ValueContainer = (valueContainerProps: ValueContainerProps) => {
      const { children } = valueContainerProps;
      const currentTotalValue = valueContainerProps.getValue().length;
      return (
        <components.ValueContainer {...valueContainerProps}>
          {children}
          {!!currentTotalValue && multiLabel && <Label m="0 10px 0 0">{multiLabel}</Label>}
          {!!currentTotalValue && <MultiCount>{currentTotalValue}</MultiCount>}
        </components.ValueContainer>
      );
    };

    return (
      <Select
        className={className}
        classNamePrefix="Select"
        components={isMulti ? { Option, MultiValue: () => null, ValueContainer } : undefined}
        css={baseStyle}
        closeMenuOnSelect={!isMulti}
        blurInputOnSelect={!isMulti}
        hideSelectedOptions={false}
        isMulti={isMulti}
        styles={{ ...selectStyles(errors, warnings, touched), ...customStyles }}
        {...props}
      />
    );
  }
}

export const SelectComponent: React.FC<Props> = ({
  handleChange,
  handleTouch,
  label,
  name,
  setFieldTouched,
  setFieldValue,
  subtext,
  ...props
}) => {
  let values: string;
  const onChange = (selected: TValue[] | TValue | unknown) => {
    values = Array.isArray(selected) ? selected.map(s => s.value).join(', ') : (selected as TValue).value;
  };

  const { wrapperStyle, meta, options } = props;
  const { initialValue } = meta ?? {};

  const onBlur =
    typeof setFieldTouched !== 'undefined'
      ? () => {
          if (typeof handleTouch !== 'undefined') {
            handleTouch(values);
          } else {
            setFieldTouched(name, true);
          }
          if (typeof setFieldValue !== 'undefined' && typeof values !== 'undefined') {
            setFieldValue(name, values);
          }
        }
      : undefined;

  const id = `select-${name.toLowerCase().replace(/\s*/g, '')}`;
  const inputId = `select-${name.toLowerCase().replace(/\s*/g, '')}-input`;

  return (
    <Flex flexDirection="column" css={wrapperStyle}>
      {label && <Label htmlFor={inputId}>{label}</Label>}
      <SelectInput
        id={id}
        inputId={inputId}
        name={name}
        onChange={handleChange || onChange}
        onBlur={onBlur}
        openMenuOnFocus={true}
        tabSelectsValue={true}
        defaultValue={options.find(o => o.value === initialValue)}
        {...props}
      />
      {subtext && (
        <Paragraph size="small" color="blueGrey" mt="6px">
          {subtext}
        </Paragraph>
      )}
    </Flex>
  );
};
