import styled from '@emotion/styled';

import { COLORS } from '../../constants/styles';
import { Paragraph } from '../Paragraph';

export const Label = styled(Paragraph.withComponent('label'))`
  color: ${COLORS.slate};
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;
