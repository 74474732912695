import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as styledSystem from 'styled-system';

import { TextColors } from '@/constants/colors';

import { COLORS as COLORS_OLD, fontAvenirBold, fontAvenirRoman, shouldForwardProp } from '../constants/styles';

const baseStyle = (isBold: boolean) => css`
  ${isBold ? fontAvenirBold : fontAvenirRoman};
  font-size: 14px;
  line-height: 20px;
`;

const smallStyle = css`
  font-size: 12px;
  line-height: 20px;
`;

const largeStyle = css`
  font-size: 16px;
  line-height: 30px;
`;

const extraLargeStyle = css`
  font-size: 18px;
  line-height: 40px;
`;

const styleMap = {
  small: smallStyle,
  large: largeStyle,
  extraLarge: extraLargeStyle,
};

interface ParagraphProps
  extends styledSystem.ColorProps,
    styledSystem.LayoutProps,
    styledSystem.SpaceProps,
    styledSystem.TypographyProps {
  color?: COLORS_OLD | TextColors;
  size?: keyof typeof styleMap;
  bold?: boolean;
}

const baseStyledSystem = styledSystem.compose(
  styledSystem.color,
  styledSystem.layout,
  styledSystem.space,
  styledSystem.typography,
);

export const Paragraph = styled('p', { shouldForwardProp })<ParagraphProps>`
  ${props => baseStyle(props.bold || false)};
  color: ${props => props.color ?? COLORS_OLD.slate};
  ${props => (props.size ? styleMap[props.size] : null)};
  ${baseStyledSystem};
`;
