import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Field, FieldProps } from 'formik';
import React from 'react';

import { COLORS, TIMING } from '../../constants/styles';
import { Label } from './Label';

const RadioButtonLabel = styled(Label)`
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
`;

const baseStyle = css`
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${COLORS.white};
  border: solid 1px ${COLORS.lightPeriwinkle};
  display: inline-block;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: border-color ${TIMING.fastest()} ease-in-out, box-shadow ${TIMING.faster()} ease-in-out;
  margin-right: 10px;
  margin-bottom: 4px;

  &:not(:disabled) {
    &:focus,
    &:checked,
    &:hover {
      border-color: ${COLORS.iris};
    }
  }

  &:checked {
    box-shadow: inset ${COLORS.iris} 0 0 0 5px;
  }

  &:disabled {
    cursor: not-allowed;

    &:checked {
      box-shadow: inset ${COLORS.paleGrey} 0 0 0 5px;
    }
  }
`;

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'form'> {
  checked?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  useField?: boolean;
  value?: string | number | string[];
}

interface InputProps extends Props {
  field?: FieldProps['field'];
  form?: FieldProps['form'];
}

const RadioButtonInput: React.FC<Omit<InputProps, 'css'>> = ({ field, form, handleChange, ...props }) => {
  const onChange = handleChange
    ? handleChange
    : (e: React.ChangeEvent<HTMLInputElement>) => {
        if (form && field) {
          form.setFieldValue(field.name, e.target.checked);
        }
      };
  if (form && field) {
    return <input type="radio" css={baseStyle} {...field} {...props} />;
  }
  return <input type="radio" onChange={onChange} css={baseStyle} {...props} />;
};

export const RadioButton: React.FC<Omit<Props, 'css'>> = ({ children, name, useField = false, ...props }) => {
  return (
    <RadioButtonLabel>
      {useField ? (
        <Field name={name} component={RadioButtonInput} {...props} />
      ) : (
        <RadioButtonInput name={name} {...props} />
      )}
      {children}
    </RadioButtonLabel>
  );
};
