import { css } from '@emotion/react';
import * as styledSystem from 'styled-system';

import { Flex } from '../Div';
import { Label } from './Label';

const getStyle = (flexDirection: Props['flexDirection']) => {
  switch (flexDirection) {
    case 'column':
      return css`
        label + label {
          margin-top: 10px;
        }
      `;
    case 'row':
      return css`
        label:not(:last-child) {
          margin-right: 20px;
        }

        input {
          margin-left: 0;
        }
      `;
    default:
      return '';
  }
};

interface Props {
  children: React.ReactNode;
  label?: string;
  flexDirection?: styledSystem.FlexDirectionProps['flexDirection'];
}

export const CheckRadioGroup: React.FC<Props> = ({ children, label, flexDirection = 'column', ...props }) => {
  const style = getStyle(flexDirection);

  return (
    <Flex flexDirection={flexDirection} css={style} {...props}>
      {label && <Label>{label}</Label>}
      {children}
    </Flex>
  );
};
