import styled from '@emotion/styled';
import { FieldProps, FormikProps } from 'formik';
import React, { forwardRef } from 'react';

import { COLORS, fontAvenirRoman } from '@/constants/styles';

import { Box } from '../Div';
import { Paragraph } from '../Paragraph';
import { Label } from './Label';

const BaseInput = forwardRef<
  HTMLInputElement,
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'form'> & {
    className?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form?: FormikProps<any>;
    trim?: boolean;
    fieldName: string;
  }
>(({ className, form, trim = false, fieldName, ...props }, ref) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Need Formik form prop to be passed for this to work.
    if (!form) {
      return;
    }

    let value = e.target.value || '';

    if (trim) {
      value = value.toLowerCase().trim();
    }

    form.setFieldValue(fieldName, value);
  };

  return <input className={className} id={fieldName} {...props} onChange={handleChange} ref={ref} />;
});

BaseInput.displayName = 'BaseInput';

const filteredProps = ['hasErrors', 'meta', 'touched'];

export const StyledInput = styled(BaseInput, {
  shouldForwardProp: prop => !filteredProps.includes(prop.toString()),
})<{
  hasErrors?: boolean;
}>`
  ${fontAvenirRoman}
  border-radius: 5px;
  border: solid 1px ${({ hasErrors }) => (hasErrors ? COLORS.lightRed : COLORS.lightPeriwinkle)};
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.slate};
  box-sizing: border-box;
  width: 100%;
  padding: 9px 15px;
  outline: none;
  cursor: text;

  &:focus {
    border-color: ${({ hasErrors }) => (hasErrors ? COLORS.lightRed : COLORS.iris)};
  }

  &::placeholder {
    color: ${COLORS.blueGrey};
    opacity: 1;
  }

  &[disabled],
  &[readonly] {
    background-color: ${COLORS.paleGrey};
    color: ${COLORS.blueGrey};

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'form'> {
  /** @errors if set to true will show a red outline around the input element if present  */
  errors?: boolean;
  field?: FieldProps['field'];
  /** Optional @label property will show a label element with text */
  label?: string;
  name?: string;
  touched?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styles?: any;
  type?: string;
  /** Optional @subtext to be displayed under the input element  */
  subtext?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form?: FormikProps<any>;
  /** Optional @trim property which will remove all whitespace `onChange`  */
  trim?: boolean;
}

export const Input = forwardRef<HTMLInputElement, Omit<InputProps, 'css'>>(
  ({ className, errors, field, label, name, styles, subtext, type = 'text', ...props }, ref) => {
    const fieldName = (field && field.name) || name || '';

    return (
      <Box className={className} css={styles}>
        {label && <Label htmlFor={fieldName}>{label}</Label>}
        <StyledInput
          id={fieldName}
          fieldName={fieldName}
          type={type}
          {...field}
          hasErrors={errors}
          {...props}
          name={name}
          ref={ref}
        />
        {subtext && (
          <Paragraph size="small" color="blueGrey" mt="6px">
            {subtext}
          </Paragraph>
        )}
      </Box>
    );
  },
);

Input.displayName = 'Input';
